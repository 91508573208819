<template>
  <span>terun</span>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex';
import { get } from 'lodash';

export default {
  name: 'ComaCose',
  data() {
    return {
      get,
    };
  },
  methods: {
    ...mapActions(['getMe']),
    ...mapMutations(['appReady', 'redirectToIP', 'setLoginInfo']),
    sendToRegistration() {
      // const referrer = '/terun';
      // this.$ls.set('referrer', referrer, 5 * 60 * 1000);
      this.redirectToIP({
        router: this.$router,
        registered: true,
        event: 'terun',
      });
    },
    handleStatus() {
      if (!get(this, 'me.registered')) {
        this.sendToRegistration();
      } else {
        const jwt = get(this, 'me.jwt');
        // const url = `https://almnft.production.adamantic.cloud/auctions/1001?token=${jwt}`;

        const url = `${get(this.conf, 'adamanticBase')}/auctions/${this.id}?token=${jwt}`;
        window.location = url;
      }
    }
  },
  computed: {
    ...mapState([
      'me',
      'conf',
    ]),
    id() {
      const { host } = window.location;
      const addendum = host.includes('acceptance') || host.includes('localhost') ? '2003' : '1001';
      return addendum;
    },
  },
  mounted() {
    let status = this.$cookies.get(`${window.TOK_NS}storageAccessData`);
    if (!status || status == null) {
      status = this.$ls.get('storageAccessData', false);
    }
    if (status && status != null) {
      this.setLoginInfo(status);
      if (!this.me) {
        this.getMe()
          .then(() => {
            this.handleStatus()
          })
          .catch((error) => {
            window.app.$log.error(error);
          });
      } else {
        this.handleStatus()
      }
    } else {
      this.sendToRegistration();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
